import React from 'react';
import { Container, Stack, Title, Text } from '@mantine/core';

const TermsAndCondition = () => {
    return (
        <Container>
            <Stack>
                <Title order={1}>Terms & Condition</Title>

                <Title order={2}>AGREEMENT TO TERMS</Title>
                <Text>
                    These Terms of Use constitute a legally binding agreement made between you,
                    whether personally or on behalf of an entity (“you”) and Hemmelig Org
                    ("Company," “we," “us," or “our”), concerning your access to and use of the
                    https://hemmelig.app website as well as any other media form, media channel,
                    mobile website or mobile application related, linked, or otherwise connected
                    thereto (collectively, the “Site”). You agree that by accessing the Site, you
                    have read, understood, and agreed to be bound by all of these Terms of Use. IF
                    YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY
                    PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.
                    Supplemental terms and conditions or documents that may be posted on the Site
                    from time to time are hereby expressly incorporated herein by reference. We
                    reserve the right, in our sole discretion, to make changes or modifications to
                    these Terms of Use from time to time. We will alert you about any changes by
                    updating the “Last updated” date of these Terms of Use, and you waive any right
                    to receive specific notice of each such change. Please ensure that you check the
                    applicable Terms every time you use our Site so that you understand which Terms
                    apply. You will be subject to, and will be deemed to have been made aware of and
                    to have accepted, the changes in any revised Terms of Use by your continued use
                    of the Site after the date such revised Terms of Use are posted. The information
                    provided on the Site is not intended for distribution to or use by any person or
                    entity in any jurisdiction or country where such distribution or use would be
                    contrary to law or regulation or which would subject us to any registration
                    requirement within such jurisdiction or country. Accordingly, those persons who
                    choose to access the Site from other locations do so on their own initiative and
                    are solely responsible for compliance with local laws, if and to the extent
                    local laws are applicable. The Site is not tailored to comply with
                    industry-specific regulations (Health Insurance Portability and Accountability
                    Act (HIPAA), Federal Information Security Management Act (FISMA), etc.), so if
                    your interactions would be subjected to such laws, you may not use this Site.
                    You may not use the Site in a way that would violate the Gramm-Leach-Bliley Act
                    (GLBA). All users who are minors in the jurisdiction in which they reside
                    (generally under the age of 18) must have the permission of, and be directly
                    supervised by, their parent or guardian to use the Site. If you are a minor, you
                    must have your parent or guardian read and agree to these Terms of Use prior to
                    you using the Site.
                </Text>

                <Title order={2}>USER REGISTRATION</Title>
                <Text>
                    You may be required to register with the Site. You agree to keep your password
                    confidential and will be responsible for all use of your account and password.
                    We reserve the right to remove, reclaim, or change a username you select if we
                    determine, in our sole discretion, that such username is inappropriate, obscene,
                    or otherwise objectionable.
                </Text>

                <Title order={2}>PROHIBITED ACTIVITIES</Title>
                <Text>
                    You may not access or use the Site for any purpose other than that for which we
                    make the Site available. The Site may not be used in connection with any
                    commercial endeavors except those that are specifically endorsed or approved by
                    us. As a user of the Site, you agree not to:
                    <li>
                        Systematically retrieve data or other content from the Site to create or
                        compile, directly or indirectly, a collection, compilation, database, or
                        directory without written permission from us.
                    </li>
                    <li>
                        Trick, defraud, or mislead us and other users, especially in any attempt to
                        learn sensitive account information such as user passwords.
                    </li>
                    <li>
                        Circumvent, disable, or otherwise interfere with security-related features
                        of the Site, including features that prevent or restrict the use or copying
                        of any Content or enforce limitations on the use of the Site and/or the
                        Content contained therein.
                    </li>
                    <li>
                        Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.
                    </li>
                    <li>
                        Use any information obtained from the Site in order to harass, abuse, or
                        harm another person.
                    </li>
                    <li>
                        Make improper use of our support services or submit false reports of abuse
                        or misconduct.
                    </li>
                    <li>
                        Use the Site in a manner inconsistent with any applicable laws or
                        regulations.
                    </li>
                    <li>Engage in unauthorized framing of or linking to the Site.</li>
                    <li>
                        Upload or transmit (or attempt to upload or to transmit) viruses, Trojan
                        horses, or other material, including excessive use of capital letters and
                        spamming (continuous posting of repetitive text), that interferes with any
                        party’s uninterrupted use and enjoyment of the Site or modifies, impairs,
                        disrupts, alters, or interferes with the use, features, functions,
                        operation, or maintenance of the Site.
                    </li>
                    <li>
                        Engage in any automated use of the system, such as using scripts to send
                        comments or messages, or using any data mining, robots, or similar data
                        gathering and extraction tools.
                    </li>
                    <li>
                        Delete the copyright or other proprietary rights notice from any Content.
                    </li>
                    <li>
                        Attempt to impersonate another user or person or use the username of another
                        user.
                    </li>
                    <li>
                        Upload or transmit (or attempt to upload or to transmit) any material that
                        acts as a passive or active information collection or transmission
                        mechanism, including without limitation, clear graphics interchange formats
                        (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes
                        referred to as “spyware” or “passive collection mechanisms” or “pcms”).
                    </li>
                    <li>
                        Interfere with, disrupt, or create an undue burden on the Site or the
                        networks or services connected to the Site.
                    </li>
                    <li>
                        Harass, annoy, intimidate, or threaten any of our employees or agents
                        engaged in providing any portion of the Site to you.
                    </li>
                    <li>
                        Attempt to bypass any measures of the Site designed to prevent or restrict
                        access to the Site, or any portion of the Site.
                    </li>
                    <li>
                        Copy or adapt the Site’s software, including but not limited to Flash, PHP,
                        HTML, JavaScript, or other code.
                    </li>
                    <li>
                        Except as permitted by applicable law, decipher, decompile, disassemble, or
                        reverse engineer any of the software comprising or in any way making up a
                        part of the Site.
                    </li>
                    <li>
                        Except as may be the result of standard search engine or Internet browser
                        usage, use, launch, develop, or distribute any automated system, including
                        without limitation, any spider, robot, cheat utility, scraper, or offline
                        reader that accesses the Site, or using or launching any unauthorized script
                        or other software.
                    </li>
                    <li>Use a buying agent or purchasing agent to make purchases on the Site.</li>
                    <li>
                        Make any unauthorized use of the Site, including collecting usernames and/or
                        email addresses of users by electronic or other means for the purpose of
                        sending unsolicited email, or creating user accounts by automated means or
                        under false pretenses.
                    </li>
                    <li>
                        Use the Site as part of any effort to compete with us or otherwise use the
                        Site and/or the Content for any revenue-generating endeavor or commercial
                        enterprise.
                    </li>
                </Text>

                <Title order={2}>TERM AND TERMINATION</Title>
                <Text>
                    These Terms of Use shall remain in full force and effect while you use the Site.
                    WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT
                    TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND
                    USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY
                    REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
                    REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY
                    APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE
                    SITE OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT
                    ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION. If we terminate or suspend
                    your account for any reason, you are prohibited from registering and creating a
                    new account under your name, a fake or borrowed name, or the name of any third
                    party, even if you may be acting on behalf of the third party. In addition to
                    terminating or suspending your account, we reserve the right to take appropriate
                    legal action, including without limitation pursuing civil, criminal, and
                    injunctive redress.
                </Text>

                <Title order={2}>DISCLAIMER</Title>
                <Text>
                    THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE
                    OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT
                    PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION
                    WITH THE SITE AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED
                    WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                    NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
                    COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE
                    SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS,
                    MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR
                    PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE
                    OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR
                    ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN,
                    (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, (5) ANY
                    BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH
                    THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT
                    AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE
                    USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
                    SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
                    PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY
                    HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER
                    OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE
                    FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF
                    PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY
                    MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE
                    CAUTION WHERE APPROPRIATE.
                </Text>

                <Title order={2}>LIMITATIONS OF LIABILITY</Title>
                <Text>
                    IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR
                    ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL,
                    SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA,
                    OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED
                    OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY
                    CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS
                    OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE LESSER OF THE
                    AMOUNT PAID, IF ANY, BY YOU TO US OR $1.00 USD. CERTAIN US STATE LAWS AND
                    INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE
                    EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR
                    ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY
                    HAVE ADDITIONAL RIGHTS.
                </Text>

                <Title order={2}>INDEMNIFICATION</Title>
                <Text>
                    You agree to defend, indemnify, and hold us harmless, including our
                    subsidiaries, affiliates, and all of our respective officers, agents, partners,
                    and employees, from and against any loss, damage, liability, claim, or demand,
                    including reasonable attorneys’ fees and expenses, made by any third party due
                    to or arising out of: (1) your Contributions; (2) use of the Site; (3) breach of
                    these Terms of Use; (4) any breach of your representations and warranties set
                    forth in these Terms of Use; (5) your violation of the rights of a third party,
                    including but not limited to intellectual property rights; or (6) any overt
                    harmful act toward any other user of the Site with whom you connected via the
                    Site. Notwithstanding the foregoing, we reserve the right, at your expense, to
                    assume the exclusive defense and control of any matter for which you are
                    required to indemnify us, and you agree to cooperate, at your expense, with our
                    defense of such claims. We will use reasonable efforts to notify you of any such
                    claim, action, or proceeding which is subject to this indemnification upon
                    becoming aware of it.
                </Text>
            </Stack>
        </Container>
    );
};

export default TermsAndCondition;
